@use "../../../colors" as *;

.mobile {
    .logo-container {
        z-index: 1;
        width: 31vh;
        position: absolute;
        bottom: 18%;
        right: 5%;
        left: auto;
        opacity: 0.3;
        transform: translatey(0px);
        animation: float 6s ease-in-out infinite;
        animation-delay: 5s;
    }

    @keyframes float {
        0% {
            transform: translatey(0px);
        }
        50% {
            transform: translatey(-20px);
        }
        100% {
            transform: translatey(0px);
        }
    }

    svg {
        width: 100%;
        height: auto;
        bottom: 0;
        //transform: rotateZ(30deg) !important;
    }

    .solid-logo {
        position: absolute;
        top: auto;
        right: auto;
        bottom: auto;
        left: 0;
        margin: auto;
        width: 100%;
        opacity: 0;
        animation: fadeIn ease 5s forwards;
        animation-delay: 5s;
        user-drag: none; 
        -webkit-user-drag: none;
        -ms-user-select: none;
        // z-index: 1;
    }

    .svg-container {
        stroke: #76ff8d44;
        stroke-width: 3px;
        animation: fadeOut 5s forwards;
        animation-delay: 4s;
    }

    @keyframes fadeout
    {

    from{opacity: 1;}
    to {opacity: 0;}

    }
}