@use '../../../colors.scss' as *;

.mobile {
    .contact-page{
        height: 100%;

        h1 {
            font-size: 6vh;
        }

        p {
            font-size: 2vh;
        }

        .text-zone {
            transform: translateY(0%);
            top: 0%;
        }
    }

    .contact-form {
        width: 100%;
        margin-top: 20px;

        ul {
            padding: 0;
            margin: 0;

            li {
                list-style: none;
                padding: 0;
                margin: 0;
                margin-bottom: 10px;
                overflow: hidden;
                position: relative;
                opacity: 0;
                animation: fadeInUp 2s 2s;
                animation-fill-mode: forwards;
                clear: both;
            }

            li.half {
                width: 49%;
                margin-left: 2%;
                float: left;
                clear: none;

                &:first-child {
                    margin-left: 0;
                }
            }
        }

        input[type="text"],
        input[type="email"] {
            width: 100%;
            border: 0;
            background-color: $dark-background;
            height: 6vh;
            font-size: 2vh;
            color: #fff;
            padding: 0 20px;
            box-sizing: border-box;
        }

        textarea {
            width: 100%;
            border: 0;
            background-color: $dark-background;
            height: 40px;
            font-size: 2vh;
            color: #fff;
            padding: 20px;
            min-height: 15vh;
            box-sizing: border-box;
        }

        .flat-button {
            color: $secondary;
            background: 0 0;
            font-size: min(2.8vw, 13px);
            letter-spacing: 3px;
            text-decoration: none;
            padding: min(2vw, 10px) min(2.5vw, 10px);
            border: 1px solid $secondary;
            float: right;
            border-radius: 4px;

            &:hover {
                background-color: $secondary;
                color: #333;
                cursor: pointer;
            }
        }
    }

    .info-map {
        position: absolute;
        opacity: 0;
        width: 1px;
        height: 1px;
        z-index: -1;

    
    }
    .map-wrap {
        position: absolute;
        opacity: 0;
        width: 1px;
        height: 1px;
        z-index: -1;
    }
}