@use "../../../colors" as *;

.nav-bar {
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 3; // Ensures the navbar is always on top of the page
    min-height: 500px;

    background: #181818;

    .page-select {
        display: block;
        text-align: center;
        position: absolute;
        top: 37.5%;
        width: 100%;
    
        a {
            display: block;
            line-height: 51px; // centers the text
            height: 51px;
            position: relative;
            
            font-size: 22px;
            color: $grey-icon;
            text-decoration: none;
    
            i {
                transition: all 0.3s ease-out;
            }
    
            &.active svg{
                color: $secondary;
            }
    
            // On hover make the icon disappear and the text appear
            &:hover {
                color: $secondary;
    
                svg {
                    opacity: 0;
                }
    
                &::after {
                    opacity: 0.6;
                }
            }
    
            &::after {
                content: ''; // added individually below
                display: block;
                width: 100%;            
                position: absolute;
                bottom: 0;
                text-align: center;
    
                font-size: 9px;
                letter-spacing: 2px;
                opacity: 0;
                transition: all 0.3s ease-out;
            }
    
            &.home-link::after {
                content: 'HOME';
            }
    
            &.about-link::after {
                content: 'ABOUT';
            }
            &.portfolio-link::after {
                content: 'PORTFOLIO';
            }
            &.contact-link::after {
                content: 'CONTACT';
            }
        }
    
    }
    
    .outside-acc {
        display: block;
        width: 100%;
        position: absolute;
        bottom: 20px;
        padding: 0;
        margin: 0;
    
        list-style: none;
        text-align: center;
    
        li {
            a {
                display: block;
                padding: 7px 0;
                line-height: 16px;
    
                font-size: 15px;
                color: $grey-icon;
    
                &:hover svg {
                    color: $secondary;
                    transition: all 0.1s ease-out;
                }
            }
        }
    }
}

