@use "../../../colors" as *;

.non-mobile {
    .home-page {
        height: 100%;
        .text-zone {
            position: absolute;
            left: 10%;
            top: 50%;
            transform: translateY(-50%);
            width: 40%;
            max-height: 90%;
            z-index: 2;
        }

        h1 {
            color: #fff;
            font-size: 53px;
            margin: 0;
            font-family: 'Coolvetica';
            font-weight: 400;

            &::before {
                content: '<h1>';
                font-family: 'La Belle Aurore';
                color: $secondary;
                font-size: 18px;
                position: absolute;
                margin-top: -40px;
                left: 15px; // indentation
                opacity: 0.6;
            }

            &::after {
                content: '</h1>';
                font-family: 'La Belle Aurore';
                color: $secondary;
                font-size: 18px;
                position: absolute;
                margin-top: 18px;
                margin-left: 20px; // indentation
                animation: fadeIn 1s 1.7s backwards;
                opacity: 0.6;
            }
            img {
                width: 40px;
                margin-inline: 20px 3px;
                opacity: 0;
                height: auto;
                animation: rotateIn 1s linear both;
                animation-delay: 1.4s;
                user-drag: none; 
                -webkit-user-drag: none;
                -ms-user-select: none;
            }
        }
    }

    h2 {
        color: #a5a5a5;
        margin-top: 20px;
        font-weight: 400px;
        font-size: 12px;
        font-family: sans-serif;
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: $secondary;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: 'sans-serif';
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid $secondary;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;
        
        &:hover {
            background-color: $secondary;
            color: #333;
            cursor: pointer;
        }
    }

    .UseEffectStyle {
        animation: fadeIn 1s 1.9s backwards;
    }
}

.mobile-notify {
    position: absolute;
    top: -7%;
    right: 0%;
    color: white;
    font-size: 20px;
    background: #18181873;
    width: 230px;
    height: 40px;
    text-align: center;
}