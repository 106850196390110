.mobile{
  height: 100%;
  .about-page {
    width: 100%;
    height: 100%;
    position: unset;

    h1 {
      width: 80%;
      font-size: 6.1vh;
    }

    p{
      font-size: min(1.8vh, 30px);
      transform: translateY(-5%);
      backdrop-filter: blur(5px);
    }
    
    .text-zone {
      
      transform: translateY(-2%);
      top: 10%;
    }
  }

  .stage-cube-cont {
      width: 64%;
      height: 70%;
      max-height: 670px;
      z-index: -1;
      display: flex;
      align-items: flex-end;
      justify-content: center;

  }
  .cubespinner {
    z-index: -1;
    animation-name: spincube;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-duration: 12s;
    animation-delay: 1.5s;
    transform-style: preserve-3d;
    transform-origin: min(19vw,140px) min(19vw,140px) 0;
  
      div {
        position: absolute;
        width: min(37vw, 280px);
        height: min(37vw, 280px);
        border: 1px solid #ccc;
        background: rgba(52, 93, 139, 0.4);
        text-align: center;
        font-size: 120px; // icon size
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 20px 0px #76ff8d;

        .fa-fw {
          width: min(28vw, 200px);
        }
      }
    
      .face1 {
        transform: translateZ(min(19vw,140px));
        color: #dd0031;
      }
      .face2 {
        transform: rotateY(90deg) translateZ(min(19vw,140px));
        color: #f06529;
      }
      .face3 {
        transform: rotateY(90deg) rotateX(90deg) translateZ(min(19vw,140px));
        color: #28a4d9;
      }
      .face4 {
        transform: rotateY(180deg) rotateZ(90deg) translateZ(min(19vw,140px));
        color: #5ed4f4;
      }
      .face5 {
        transform: rotateY(-90deg) rotateZ(90deg) translateZ(min(19vw,140px));
        color: #efd81d;
      }
      .face6 {
        transform: rotateX(-90deg) translateZ(min(19vw,140px));
        img {
          height: auto;
          width: 90%;
        }
      }
    }
    
    @keyframes spincube {
      0% { // JS
              transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg); }
      16% { // HTML5
              transform: rotateY(-90deg); }
      33% { // CSS3
              transform: rotateY(-90deg) rotateZ(90deg) rotateX(1deg); }
      50% { // React
              transform: rotateY(-180deg) rotateZ(90deg) rotateX(1deg); }
      66% { // Python
              transform: rotateY(-270deg) rotateX(90deg) rotateZ(00deg); }
      83% { // Git
              transform: rotateX(90deg); }
  }
}