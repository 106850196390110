.non-mobile {
  height: 100%;
  width: 100%;
  .about-page {
    p {
      min-width: 400px !important;
    }
    .stage-cube-cont {
      width: 100%;
      height: 100%;
      margin: 0;
      position: absolute;
      overflow: hidden;
    }
    .cubespinner {
        width: min(40vw,557px);
        position: absolute;
        top: 40%;
        left: 25%;
        bottom: 0;
        margin: auto;
        animation-name: spincube;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-duration: 12s;
        animation-delay: 1.5s;
        transform-style: preserve-3d;
        transform-origin: 100px 100px 0;
        margin-left: calc(50% - 100px);
        
      
        div {
          position: absolute;
          width: 200px;
          height: 200px;
          border: 1px solid #ccc;
          background: rgba(52, 93, 139, 0.4);
          text-align: center;
          font-size: 120px; // icon size
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 0 0 20px 0px #76ff8d;
          font-size: 180px;
        }
      
        .face1 {
          transform: translateZ(100px);
          color: #dd0031;
        }
        .face2 {
          transform: rotateY(90deg) translateZ(100px);
          color: #f06529;
        }
        .face3 {
          transform: rotateY(90deg) rotateX(90deg) translateZ(100px);
          color: #28a4d9;
        }
        .face4 {
          transform: rotateY(180deg) rotateZ(90deg) translateZ(100px);
          color: #5ed4f4;
        }
        .face5 {
          transform: rotateY(-90deg) rotateZ(90deg) translateZ(100px);
          color: #efd81d;
        }
        .face6 {
          transform: rotateX(-90deg) translateZ(100px);
          img {
            height: auto;
            width: 90%;
          }
        }
      }
      
      @keyframes spincube {
        0% { // JS
                transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg); }
        16% { // HTML5
                transform: rotateY(-90deg); }
        33% { // CSS3
                transform: rotateY(-90deg) rotateZ(90deg) rotateX(1deg); }
        50% { // React
                transform: rotateY(-180deg) rotateZ(90deg) rotateX(1deg); }
        66% { // Python
                transform: rotateY(-270deg) rotateX(90deg) rotateZ(00deg); }
        83% { // Git
                transform: rotateX(90deg); }
    }
  }
}